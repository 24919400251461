import React from "react";
import { guideModalAtom } from "@/Atoms/modal/DialogueAtoms";
import { useRecoilState } from "recoil";
import { Dialog, DialogContent } from "../ui/dialog";

const GuideModal = () => {
  const [showGuideModal, setShowGuideModal] = useRecoilState(guideModalAtom);
  return (
    <Dialog
      open={true}
      onOpenChange={() => setShowGuideModal({ isOpen: false, link: "" })}
    >
      <DialogContent className="max-w-screen-xl w-full">
        <div className="sl-embed relative mt-4 pb-[calc(50.84%+27px)] w-full h-0 scale-100">
          <iframe
            className="sl-demo absolute top-0 left-0 w-full h-full border shadow-[0_0_18px_rgba(26,19,72,0.15)] rounded-lg box-border"
            // src="https://www.youtube.com/embed/1EabFDh8IeE"
            src={
              showGuideModal?.link ||
              "https://www.youtube.com/embed/ST0H5VLYC4w"
            }
            name="sl-embed"
            allow="fullscreen; camera; microphone"
          ></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GuideModal;
