export const LOGOS = {
  BRAND: {
    LIGHT: {
      icon: "/images/logos/antos/icon.png",
      small: "/images/logos/plus100.png",
      medium: "/images/logos/plus100.png",
      large: "/images/logos/plus100.png",
    },
    DARK: {
      icon: "/images/logos/antos/icon.png",
      small: "/images/logos/plus100.png",
      medium: "/images/logos/plus100.png",
      large: "/images/logos/plus100.png",
    },
  },
  BROKER1: {
    LIGHT: {
      icon: "/images/logos/antos/icon.png",
      small: "/images/logos/antos/icon.png",
      medium: "/images/logos/antos/icon.png",
      large: "/images/logos/antos/icon.png",
    },
    DARK: {
      icon: "/images/logos/antos/icon.png",
      small: "/images/logos/plus100.png",
      medium: "/images/logos/plus100.png",
      large: "/images/logos/plus100.png",
    },
  },
  BROKER2: {
    LIGHT: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
    DARK: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
  },
};
