"use client";
import React, { createContext, useContext, useState, useEffect } from "react";

interface User {
  email: string;
  first_name: string;
  last_name: string;
  unique_key: string;
  profile_image: string;
  trading_challenge: string;
  description: string;
  is_active: boolean;
  user_type: string;
  meta_data: string;
  created_at: string;
}

interface Preference {
  country: string;
  trading_yoe: string;
  risk_class: string;
  language: string;
  asset_class: string;
  notification_preference: string;
  nudges_tracker: string;
  walkthrough_status: string;
  webhook: string;
  send_email: string;
  socials: string;
}

interface UserData {
  user: User;
  preference: Preference;
  is_old_user: boolean;
  user_id: string;
  hmac: string;
  token: string;
  subscription: string;
  start_date: string;
  redirect_after_login: string;
}

interface UserContextType {
  user: UserData | null;
  setUser: (user: UserData | null) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUserState] = useState<UserData | null>(null);

  const setUser = (newUser: UserData | null) => {
    setUserState(newUser);
    if (typeof window !== "undefined") {
      if (newUser) {
        localStorage.setItem("user-info", JSON.stringify(newUser));
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedUser = localStorage.getItem("user-info");
      if (storedUser) {
        setUserState(JSON.parse(storedUser));
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
