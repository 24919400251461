import { API_URL } from "@/config";
import axios from "axios";
import {
  useState,
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useContext,
} from "react";

type PreBuiltScannerData = Array<{
  keygen: string;
  canvas_data: string;
}>;

type PrebuiltScannerDataContextType = {
  prebuiltScannerData: PreBuiltScannerData;
  fetchPrebuiltData: (
    keygen: string,
    forceRefetch?: boolean,
    userId?: string
  ) => void;
};

const init = {
  prebuiltScannerData: [],
  fetchPrebuiltData: () => null,
};

const PrebuiltScannerDataContext =
  createContext<PrebuiltScannerDataContextType>(init);

export const PrebuiltScannerDataProvider = (props: { children: ReactNode }) => {
  const [prebuiltScannerData, setprebuiltScannerData] =
    useState<PreBuiltScannerData>([]);

  const fetchPrebuiltData = useCallback(
    async (
      keygen: string,
      forceRefetch: boolean = false,
      userId: string = ""
    ) => {
      if (forceRefetch || prebuiltScannerData?.length === 0) {
        try {
          const userInfo = JSON.parse(localStorage.getItem("user-info")!);
          const url = new URL("pre_built_strategies_view_data", API_URL);
          // url.searchParams.append("user_id", `${userId ? userId : "wXmR4a"}`);
          url.searchParams.append("keys", keygen);

          const res = await axios.post(
            url.toString(),
            {},
            {
              headers: {
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
          );

          const data: PreBuiltScannerData = res?.data;

          if (res.status === 200) {
            setprebuiltScannerData(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [prebuiltScannerData]
  );

  return (
    <PrebuiltScannerDataContext.Provider
      value={{
        prebuiltScannerData,
        fetchPrebuiltData,
      }}
    >
      {props.children}
    </PrebuiltScannerDataContext.Provider>
  );
};

export const usePrebuiltScannerData = () =>
  useContext(PrebuiltScannerDataContext);
