import {
  brokerConnectModalAtom,
  guideModalAtom,
  technicalSupportModalAtom,
} from "@/Atoms/modal/DialogueAtoms";
import React from "react";
import { useRecoilState } from "recoil";
import BrokerConnectModal from "../common/BrokerConnectModal";
import TechnicalRequestModal from "../common/TechnicalRequestModal";
import GuideModal from "../common/GuideModal";

interface DialogueWrapperProps {
  children?: React.ReactNode;
}

const DialogueWrapper = ({ children }: DialogueWrapperProps) => {
  const [showConnectForm, setShowConnectForm] = useRecoilState(
    brokerConnectModalAtom
  );
  const [showTechnicalRequest, setShowTechnicalRequest] = useRecoilState(
    technicalSupportModalAtom
  );
  const [showGuideModal, setShowGuideModal] = useRecoilState(guideModalAtom);

  return (
    <div>
      {showConnectForm && <BrokerConnectModal />}
      {showTechnicalRequest?.isOpen && <TechnicalRequestModal />}
      {showGuideModal?.isOpen && <GuideModal />}

      <div>{children}</div>
    </div>
  );
};

export default DialogueWrapper;
