import { ReactNode, createContext, useContext, useState } from "react";

type QuickStrategyContextType = {
  openQuickStrategy: boolean;
  isScannerType: boolean;
  setQuickStrategyModalToggle: (isOpen: boolean) => void;
  changeScannerType: (isScanner: boolean) => void;
};

const initialState = {
  openQuickStrategy: false,
  isScannerType: false,
  setQuickStrategyModalToggle: (isOpen = false) => null,
  changeScannerType: (isScanner = false) => null,
};

export const QuickStrategyContext =
  createContext<QuickStrategyContextType>(initialState);

export const QuickStrategyContextProvider = (props: {
  children: ReactNode;
}) => {
  const [openQuickStrategy, setModalToggle] = useState<boolean>(false);
  const [isScannerType, setIsScannerType] = useState<boolean>(false);

  const setQuickStrategyModalToggle = (isOpen: boolean) => {
    setModalToggle(isOpen);
  };

  const changeScannerType = (isScanner: boolean) => {
    setIsScannerType(isScanner);
  };

  return (
    <QuickStrategyContext.Provider
      value={{
        isScannerType,
        openQuickStrategy,
        changeScannerType,
        setQuickStrategyModalToggle,
      }}
    >
      {props.children}
    </QuickStrategyContext.Provider>
  );
};

export const useQuickStrategyContext = () => useContext(QuickStrategyContext);
