import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type DarkThemeContextType = {
  darkTheme: boolean;
  setDarkTheme: (value: boolean) => any;
};

const init = {
  darkTheme: false,
  setDarkTheme: () => null,
};

export const DarkThemeContext = createContext<DarkThemeContextType>(init);

export const DarkThemeContextProvider = (props: { children: ReactNode }) => {
  const [darkTheme, setDarkTheme] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (darkTheme) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
      localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
    }
  }, [darkTheme]);
  return (
    <DarkThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
      {props.children}
    </DarkThemeContext.Provider>
  );
};

export const useDarkTheme = () => useContext(DarkThemeContext);
