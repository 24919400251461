import { SUPPORT_MODAL_TITLE } from "@/lib/utils";
import { createContext, ReactNode, useContext, useState } from "react";

type LoadingContextType = {
  showTechnicalRequest: boolean;
  setShowTechnicalRequest: (value: any) => any;
  title: string;
  setTitle: (value: string) => any;
};

const init = {
  showTechnicalRequest: false,
  setShowTechnicalRequest: () => null,
  title: SUPPORT_MODAL_TITLE.TECHNICAL_SUPPORT,
  setTitle: () => null,
};

export const TechnicalRequest = createContext<LoadingContextType>(init);

export const TechnicalRequestContextProvider = (props: {
  children: ReactNode;
}) => {
  const [showTechnicalRequest, setShowTechnicalRequest] =
    useState<boolean>(false);
  const [title, setTitle] = useState<string>("Technical Support");

  return (
    <TechnicalRequest.Provider
      value={{
        showTechnicalRequest,
        setShowTechnicalRequest,
        title,
        setTitle,
      }}
    >
      {props.children}
    </TechnicalRequest.Provider>
  );
};

export const useTechnicalRequest = () => useContext(TechnicalRequest);
