import { skipToken, useMutation, useQuery } from "@tanstack/react-query";
import { baseApi } from "./baseApi";

export const useGetUserWalkthroughStatus = (user_id?: string) => {
  return useQuery({
    queryKey: ["user-walkthrough-status", user_id],
    queryFn: !user_id
      ? skipToken
      : async () => {
          const response = await baseApi.get(
            `/get_user_walkthrough_status?user_id=${user_id}`
          );
          return response;
        },
    enabled: !!user_id,
    retry: 0,
    staleTime: 3,
  });
};

export const usePostSetUserWalkthroughStatus = () => {
  return useMutation({
    mutationKey: ["user-walkthrough-status"],
    mutationFn: async ({
      user_id,
      step,
    }: {
      user_id?: string;
      step?: string;
    }) => {
      const response = await baseApi.post(
        `/set_user_walkthrough_status?user_id=${user_id}&walkthrough_stage=${step}`
      );
      return response;
    },
  });
};

export const usePostSupportRequest = () => {
  return useMutation({
    mutationKey: ["support-request"],
    mutationFn: async ({
      user_id,
      messege,
    }: {
      user_id?: string;
      messege?: string;
    }) => {
      const response = await baseApi.post(
        `/support_request?user_id=${user_id}&message=${messege}`
      );
      return response;
    },
  });
};

export const useGetUserNotification = (user_id?: string, page?: number) => {
  return useQuery({
    queryKey: ["get_deployment_notifications", user_id, page],
    queryFn: !user_id
      ? skipToken
      : async () => {
          const response = await baseApi.get(
            `/get_deployment_notifications?user_id=${user_id}&page=${
              page || 1
            }&size=5` //default limit for notification
          );
          return response;
        },
    enabled: !!user_id,
    retry: 0,
    staleTime: 3,
  });
};

export const useGetUserAllNotification = (user_id?: string, page?: number) => {
  return useQuery({
    queryKey: ["get_all_notification_deployments", user_id, page],
    queryFn: !user_id
      ? skipToken
      : async () => {
          const response = await baseApi.get(
            `/get_all_notification_deployments?user_id=${user_id}&page=${
              page || 1
            }&size=5` //default limit for notification
          );
          return response;
        },
    enabled: !!user_id,
    retry: 0,
    staleTime: 3,
  });
};

export const usePostReadDeploymentNotification = () => {
  return useMutation({
    mutationKey: ["read_deployment_notifications"],
    mutationFn: async ({ user_id }: { user_id?: string }) => {
      const response = await baseApi.post(
        `/read_deployment_notifications?user_id=${user_id}`
      );
      return response;
    },
  });
};

export const usePostSetLanguagePref = () => {
  return useMutation({
    mutationKey: ["set_language_preference"],
    mutationFn: async ({
      user_id,
      language_pref,
    }: {
      user_id?: string;
      language_pref?: string;
    }) => {
      const response = await baseApi.post(
        `/set_language_preference?user_id=${user_id}&language_pref=${language_pref}`
      );
      return response;
    },
  });
};

export const usePostLogin = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: async ({ formData }: { formData: any }) => {
      const response = await baseApi.post(`/login`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      return response;
    },
  });
};

export const usePostVerify = () => {
  return useMutation({
    mutationKey: ["verify_session"],
    mutationFn: async () => {
      const response = await baseApi.post(`/verify_session`);

      return response;
    },
  });
};

export const usePostUpdateUserDescribtion = () => {
  return useMutation({
    mutationKey: ["update_user_description"],
    mutationFn: async ({
      user_id,
      description,
    }: {
      user_id: string;
      description: string;
    }) => {
      const response = await baseApi.post(
        `/update_user_description?user_id=${user_id}&description=${description}`
      );

      return response;
    },
  });
};

export const usePostUpdateSocials = () => {
  return useMutation({
    mutationKey: ["update_user_socials"],
    mutationFn: async ({
      user_id,
      twitter,
      linkedin,
    }: {
      user_id: string;
      twitter: string;
      linkedin: string;
    }) => {
      const response = await baseApi.post(
        `/update_user_socials?user_id=${user_id}&twitter=${twitter}&linkedin=${linkedin}`
      );

      return response;
    },
  });
};

export const usePostUploadProfilePhoto = () => {
  return useMutation({
    mutationKey: ["upload_profile_image"],
    mutationFn: async ({
      user_id,
      formData,
    }: {
      user_id: string;
      formData: any;
    }) => {
      const response = await baseApi.post(
        `/upload_profile_image?user_id=${user_id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      return response;
    },
  });
};
