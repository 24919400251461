import { atom } from "recoil";

export const brokerConnectModalAtom = atom({
  key: "brokerConnectModalAtom",
  default: false, // Provide a default value
});

export const technicalSupportModalAtom = atom({
  key: "technicalSupportModalAtom",
  default: {
    isOpen: false,
    isRequest: false,
  },
});

export const guideModalAtom = atom({
  key: "guideModalAtom",
  default: {
    isOpen: false,
    link: "",
  },
});
