import { createContext, ReactNode, useContext, useState } from "react";

type UpgradePlanModalContextType = {
  openUpgradePlan: boolean;
  closeUpgradePlanModal: () => void;
  openUpgradePlanModal: (show: boolean, title: string, body: any) => void;
  upgradePlanSize: string;
  setUpgradePlanSize: (value: string) => void;
  upgradePlanBody: any;
  setUpgradePlanBody: (value: any) => any;
};

const init = {
  openUpgradePlan: false,
  closeUpgradePlanModal: () => null,
  openUpgradePlanModal: () => "",
  upgradePlanSize: "",
  setUpgradePlanSize: () => "",
  upgradePlanBody: null,
  setUpgradePlanBody: () => null,
};

export const UpgradePlanModalContext =
  createContext<UpgradePlanModalContextType>(init);

export const UpgradePlanModalContextProvider = (props: {
  children: ReactNode;
}) => {
  const [openUpgradePlan, setUpgradePlanModalToggle] = useState<boolean>(false);
  const [upgradePlanSize, setUpgradePlanSize] = useState<string>("max-w-7xl");
  const [upgradePlanBody, setUpgradePlanBody] = useState<any>();

  const openUpgradePlanModal = (show: boolean, size: string, body: any) => {
    setUpgradePlanModalToggle(show);
    setUpgradePlanSize(size);
    setUpgradePlanBody(body);
  };

  const closeUpgradePlanModal = () => {
    setUpgradePlanModalToggle(false);
  };

  return (
    <UpgradePlanModalContext.Provider
      value={{
        openUpgradePlan,
        openUpgradePlanModal,
        closeUpgradePlanModal,
        upgradePlanSize,
        setUpgradePlanSize,
        upgradePlanBody,
        setUpgradePlanBody,
      }}
    >
      {props.children}
    </UpgradePlanModalContext.Provider>
  );
};

export const useUpgradePlanModal = () => useContext(UpgradePlanModalContext);
