import { API_URL } from "@/config";
import axios from "axios";

export const baseApi = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  validateStatus: (status) => status < 400, // Success if status is 2xx or 3xx
});

// Add interceptor to dynamically get token for each request
baseApi.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user-info")!); //TODO: Remove local stroage and store in context
  config.headers.Authorization = `Bearer ${user?.token}`;
  return config;
});
