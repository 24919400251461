import React, { MouseEventHandler, useEffect, useState } from "react";
import { CardContent, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { Textarea } from "../ui/textarea";
import { SUPPORT_MODAL_TITLE } from "@/lib/utils";
import { Loader2 } from "lucide-react";
import { usePostSupportRequest } from "@/api/userApi";
import { useUserContext } from "@/contexts/userContext";
import { useToastHelper } from "@/helpers/helperFunctions";
import { Dialog, DialogContent } from "../ui/dialog";
import { technicalSupportModalAtom } from "@/Atoms/modal/DialogueAtoms";
import { useRecoilState } from "recoil";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const TechnicalRequestModal = () => {
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState<any>();
  const {
    mutateAsync: postSupportRequest,
    isPending: messageLoader,
    isSuccess: messegeSuccess,
    data: messegeData,
  } = usePostSupportRequest();
  const { user } = useUserContext();
  const { toast } = useToastHelper();
  const { t } = useTranslation("layout");

  const [showTechnicalRequest, setShowTechnicalRequest] = useRecoilState(
    technicalSupportModalAtom
  );
  const title = showTechnicalRequest.isRequest
    ? SUPPORT_MODAL_TITLE.REQUEST_ACCESS
    : SUPPORT_MODAL_TITLE.TECHNICAL_SUPPORT;
  const onSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    setMessage(message.trim() || "");
    if (!message?.trim?.()) {
      setMessageError(true);
      return;
    }
    setMessageError(false);
    await postSupportRequest({
      user_id: user?.user_id,
      messege: message?.trim?.(),
    });
  };

  useEffect(() => {
    if (messegeSuccess) {
      toast.success(messegeData.data.message);
      setShowTechnicalRequest({ isOpen: false, isRequest: false });
    }
  }, [messegeSuccess, messageLoader]);

  return (
    <Dialog
      open={true}
      onOpenChange={() => {
        setShowTechnicalRequest({ isOpen: false, isRequest: false });
        setMessageError(false);
      }}
    >
      <DialogContent>
        <CardHeader className="px-0">
          <CardTitle className="text-2xl whitespace-nowrap py-0 font-bold tracking-tight">
            {t(`${title}`)}
          </CardTitle>
        </CardHeader>
        <CardContent className="px-0">
          <div>
            <Textarea
              name="Text1"
              placeholder={t(
                title === "Request Access"
                  ? `Curious about Plus100? Want free access or have questions? We're here to help. Send us a message, and we'll get back to you ASAP`
                  : "We're here to help with any issues or questions you might have. Please share a message using this form, and our technical support team will assist you as quickly as possible."
              )}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              className="w-full"
              rows={15}
            />
            <div>
              {messageError && (
                <p className="text-destructive text-sm">Message is required.</p>
              )}
            </div>
          </div>
        </CardContent>
        <CardFooter className="grow px-0">
          <div className="flex w-full h-full">
            <div className="flex flex-col gap-3 w-full float-right h-full">
              <div className="flex flex-col w-full lg:flex-row float-right">
                <div className="w-full">
                  <Button
                    disabled={messageLoader}
                    onClick={onSubmit}
                    className="text-sm gap-2 float-right"
                  >
                    Submit
                    {messageLoader && (
                      <Loader2 className="ml-2 h-5 w-5 animate-spin" />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CardFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TechnicalRequestModal;
