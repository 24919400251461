import { atom } from "recoil";

export interface BrokerFlagState {
  isConnected: boolean;
  connectedBrokers?: any;
  virtualFunds?: any;
  liveFunds?: any;
  loading: boolean;
  apiCalling: boolean;
}

const defaultBrokerState: BrokerFlagState = {
  isConnected: false,
  connectedBrokers: [],
  virtualFunds: {},
  liveFunds: [],
  loading: true,
  apiCalling: true
};

export const brokerFlagState = atom({
  key: "brokerFlag",
  default: defaultBrokerState,
});
